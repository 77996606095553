import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MapIcon from '../assets/icons-for-map-feature/MapIcon.svg';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Tabs,
  Tab,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useStateValue } from '../state';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { NAV_OPTIONS, STATUS } from '../utils/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import api from '../utils/api';
import cookies from '../cookies';
import AccountMenu from './AccountMenu';
import { capitalizeFirstOfEach, parseIdFromLink, navbarTabLink, navBarSelectRedirect } from '../utils/helpers';
import useDebounce from '../hooks/useDebounce';
import LoginSignUpBtns from './common/LoginSignUpBtn';
import { useLocation } from 'react-router-dom';
import useClientLocation from '../hooks/useClientLocation';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 0,
  },
  appBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 99,
    backgroundColor: theme.palette.secondary.contrastText,
    boxShadow: '0px 0px',
    borderBottom: '1px solid #ebebeb',
  },
  appBarInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    color: theme.palette.secondary.main,
  },
  drawerButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  homeButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
  },
  logoHeader: {
    width: 190,
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
  },
  notificationsButton: {
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  profileButton: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  accountMenu: {
    fontWeight: 'bold',
    boxShadow: '0px 0px',
    margin: theme.spacing(0.5),
    height: 35,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  navBarTabs: {
    flexGrow: 1,
    color: '#9ea137 !important',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tab: {
    //Rmv padding for fit clinics, add flex grow
    flexGrow: 1,
    color: '#101010 !important',
    fontWeight: '700',
    fontSize: '18pt',
    minWidth: 50,
    // paddingLeft: 32,
    // paddingRight: 32,
  },
  selectedTab: {
    color: '#9ea137 !important',
    fontWeight: '900',
    fontSize: '18pt',
    minWidth: 50,
    // paddingLeft: 32,
    // paddingRight: 32,
  },
  list: {
    width: theme.screen.drawer.width,
  },
  offset: {
    ...theme.mixins.largeToolbar,
    flexGrow: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabRoot: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    // borderLeft: '33px solid white',
    // borderRight: '33px solid white',
    height: 3.5,
  },
  searchToolbar: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99,
    width: '100%',
    height: 60,
    backgroundColor: '#ebebeb',
    padding: '0 70px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 2px',
    },
  },
  searchInner: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchToolbarInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: "flex-end",
    justifyContent: 'space-between',
    maxWidth: theme.screen.maxWidth,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
    },
  },
  searchToolbarInnerGlobal: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '49vw',
  },
  searchToolbarInnerLocation: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '49vw',
    '@media (min-width: 768px)': {
      // marginRight: theme.spacing(3),
    },
  },
  locationAutocomplete: {
    width: 350,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexGrow: 2,
      '& .MuiAutocomplete-inputRoot': {
        padding: '3px !important',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '0.75rem',
        //   padding: '6px',
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
    },
  },
  locationSearchBox: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.5rem',
    },
  },
  locationBoxLabel: {
    fontWeight: 'bold',
    fontSize: '11pt',
    color: '#3c3f3d',
    marginTop: 3,
    textAlign: 'end',
    marginRight: 8,
    // ao: remove for mobile:
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  globalSearchAutocomplete: {
    width: 350,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 2,
      '& .MuiAutocomplete-inputRoot': {
        padding: '3px !important',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '0.75rem',
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
    },
  },
  globalSearchBox: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '5%',
  },
  inputAdornment: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      height: 'fit-content',
      width: 'fit-content',
    },
  },
  searchBoxIcon: {
    color: '#9e9e9e',
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
      width: '1rem',
      height: '1rem',
    },
  },
  listbox: {
    '@media (max-width: 500px)': {
      // width: '175%',
      margin: 0,
      padding: 0,
      fontSize: '0.5rem',
      // lineHeight: '0.5rem',
    },
  },
  nearestToMeButton: {
    padding: '3px',
    margin: '0px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    boxShadow: '0px 0px',
    lineHeight: 1,
    height: 35,
    width: 120,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      padding: '0.1rem',
      margin: '0',
      height: '25px',
      marginTop: '3px',
    },
  },
}));

// ao: sdaw-1293 - custom popper with its own style to manipulate font size of global search results.
const useStylesAlt = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: 101,
      '& .MuiAutocomplete-listbox': {
        '@media (max-width: 500px)': {
          fontSize: '0.75rem',
          '& .MuiAutocomplete-option': {
            minHeight: 'fit-content',
          },
        },
      },
    },
  })
);

const CustomPopper = (props) => {
  const classes = useStylesAlt();
  return <Popper {...props} className={classes.root} placement="bottom" />;
};

function AppBarOffset() {
  const classes = useStyles();
  return <div className={classes.offset} />;
}

const getLocations = async () => {
  return api
    .getLocations()
    .then((res) =>
      res._embedded.locations.sort((first, second) => {
        if (first.province.localeCompare(second.province) === 0) {
          return first.city.localeCompare(second.city);
        } else return first.province.localeCompare(second.province);
      })
    )
    .catch((error) => {
      throw STATUS.ERROR;
    });
};

const getLocationId = async (province, city) => {
  try {
    return api.getLocationBySearch(province, city).then((res) => res.data._embedded.locations[0]._links.self.href);
  } catch (error) {
    throw error;
  }
};

// ao: sdaw-1044 - new api calls for global search (stores, brands and products)
const getGlobalStoresByName = async (name, published) => {
  return api
    .getGlobalStoresByName(name, published)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getGlobalBrandsByName = async (name, published) => {
  return api
    .getGlobalBrandsByName(name, published)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};
const getGlobalProductsByName = async (name, removeFromMenu, detailed, size) => {
  return api
    .getGlobalProductsByName(name, removeFromMenu, detailed, size)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function NavBar(props) {
  const classes = useStyles();
  const routerLocation = useLocation();

  const { value, handleChange, logout } = props;

  const [drawerState, setDrawerState] = useState({
    left: false,
    right: false,
  });

  const [locationChanged, setLocationChanged] = useState(false);

  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState({ ...drawerState, [side]: open });
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {side === 'left' && (
        <List>
          {NAV_OPTIONS.map((option, index) => (
            <ListItem
              button
              key={option.name}
              onClick={(event) => handleChange(event, index)}
              component={Link}
              to={navbarTabLink(option.path, location.province, location.city)}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <ListItemText primary={option.name} />
            </ListItem>
          ))}
        </List>
      )}
      {side === 'right' && (
        <List>
          {(!cookies.getJWT() || !cookies.getUserID() || !cookies.getRole()) &&
            ['Login', 'Sign Up'].map((text, index) => (
              <ListItem
                button
                key={text}
                component={Link}
                to={{ pathname: `/${text.toLowerCase().split(' ').join('')}`, prevPath: routerLocation.pathname }}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          {cookies.getJWT() &&
            cookies.getUserID() &&
            cookies.getRole() &&
            ['Profile', 'Notifications'].map((text, index) => {
              return (
                <ListItem
                  button
                  key={text}
                  component={Link}
                  to={`/${text.toLowerCase().split(' ').join('')}`}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
          {['ROLE_STORE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole()) && (
            <ListItem
              button
              key={'Client Portal'}
              component={Link}
              to={`/client`}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <ListItemText primary={'Client Portal'} />
            </ListItem>
          )}
          {['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole()) && (
            <ListItem
              button
              key={'Admin Portal'}
              component={Link}
              to={`/admin`}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <ListItemText primary={'Admin Portal'} />
            </ListItem>
          )}
          {cookies.getJWT() && cookies.getUserID() && cookies.getRole() && (
            <ListItem button onClick={() => logout()}>
              <ListItemText primary={'Logout'} />
            </ListItem>
          )}
        </List>
      )}
    </div>
  );

  const mobile = useMediaQuery('(max-width:1300)');

  const [locations, setLocations] = useState([]);
  const [{ location }, dispatch] = useStateValue();
  //ao:
  const [inputValue, setInputValue] = useState(null);
  const searchTerm = useDebounce(inputValue, 400);
  const [term, setTerm] = useState(null);
  const [results, setResults] = useState([]);
  const onSearch = useCallback((sterm) => setTerm(sterm), []);

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  useEffect(() => {
    setResults([]);
    // ao: sdaw-1044 - global search - nested calls to force drop-down list order
    term &&
      term.length > 2 &&
      getGlobalStoresByName(term, true)
        .then((res) => {
          setResults((prev) => [...prev, ...res.data._embedded.stores]);
          getGlobalBrandsByName(term, true).then((res) => {
            setResults((prev) => [
              ...prev,
              ...res.data._embedded.brands.map((brand) => ({ ...brand, entityType: 'BRAND' })),
            ]);
            // ao: sdaw-1182 - added detailed=true flag to getGlobalProductsByName to remove non-brand products that don't belong to any published stores.
            getGlobalProductsByName(term, false, true, 30).then((res) => {
              // sdaw-1182 - remove non-brand products that don't belong to any published stores.
              const filteredProducts = res.data._embedded.products
                .filter((product) => {
                  //Only show products that belong to a published store or brand
                  return product.brand?.published || product.stores.filter((store) => store.published).length > 0;
                })
                .map((product) => {
                  //Add flag to indicate that this is a product
                  return { ...product, entityType: 'PRODUCT' };
                });
              setResults((prev) => [...prev, ...filteredProducts]);
            });
          });
        })
        .catch((error) => console.error(error));
  }, [term]);

  //===============================Use Effect for locations
  const { closestLocation, updateClostestLocation, loadingNearestLoc } = useClientLocation({ locationList: locations });
  const determineClosestLocation = () => {
    updateClostestLocation(); //Updates clostestLocation
  };
  useEffect(() => {
    //When closest location is found, update location
    //TODO - move this to the useClientLocationHook so its self contained
    if (closestLocation) {
      setLocationChanged(true);
      dispatch({
        type: 'changeLocation',
        newLocation: {
          province: closestLocation.province,
          city: closestLocation.city,
          link: closestLocation.link,
        },
      });
      cookies.setLocation({
        province: closestLocation.province,
        city: closestLocation.city,
        link: closestLocation.link,
      });
    }
  }, [dispatch, closestLocation]);

  useEffect(() => {
    if (!location.link) {
      getLocationId(location.province, location.city)
        .then((res) => {
          setLocationChanged(true);
          dispatch({
            type: 'changeLocation',
            newLocation: {
              province: location.province,
              city: location.city,
              link: res,
            },
          });

          cookies.setLocation({
            province: location.province,
            city: location.city,
            link: res,
          });
        })
        .catch((error) => console.error(error));
    }
    getLocations()
      .then((res) => {
        setLocations(res);
      })
      .catch((error) => console.error(error));
  }, [location, dispatch]);

  useEffect(() => {
    if (
      !locationChanged &&
      locations.length > 1 &&
      ['deliveries', 'delivery', 'stores', 'deals', 'maps'].includes(window.location.href.split('/')[3])
    ) {
      const prov =
        window.location.href.split('/')[4] &&
        decodeURI(capitalizeFirstOfEach(window.location.href.split('/')[4].toLowerCase().replace('-', ' ')));
      const city =
        window.location.href.split('/')[5] &&
        decodeURI(capitalizeFirstOfEach(window.location.href.split('/')[5].toLowerCase().replace('-', ' ')));

      const found = locations.find((loc) => loc.province === prov && loc.city === city);

      if (found) {
        setLocationChanged(true);
        const loc = {
          province: found.province,
          city: found.city,
          link: parseIdFromLink(found._links.location.href, 0, '{?projection}'),
        };

        dispatch({
          type: 'changeLocation',
          newLocation: loc,
        });

        cookies.setLocation(loc);
      }
    }
  }, [locations, locationChanged, dispatch]);

  const isLoggedIn = cookies.getJWT() && cookies.getUserID() && cookies.getRole();
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.searchToolbar} id="upperNav">
          <div id="innerUpper" className={classes.searchInner}>
            {/* ao: ======================================Global search bar begins*/}
            <div className={classes.searchToolbarInnerGlobal}>
              <Typography className={classes.locationBoxLabel}>Search For:</Typography>
              <Autocomplete
                className={classes.globalSearchAutocomplete}
                noOptionsText=""
                disableClearable
                options={results}
                // getOptionLabel={(option) => option.name}
                // ao: sdaw-1293 - custom popper with its own style to manipulate font size of global search results.
                PopperComponent={CustomPopper}
                renderOption={(option) => (
                  <Fragment>
                    <span>
                      <strong>
                        {option.storeType && option.name + '  [' + option.storeType + '] - ' + option.address.city}
                        {option.entityType === 'BRAND' && option.name + '  [Brand]'}
                      </strong>
                      {option.entityType === 'PRODUCT' && option.name + '  [Product]'}
                    </span>
                  </Fragment>
                )}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                  const id = parseIdFromLink(newValue._links.self.href, 0);
                  // ao: SDAW-1044 - determine selected newValue type (storefront, delivery, brand or product) and redirect accordingly:
                  /* TODO - REFACTOR - this should use proper links not background redirects */
                  if (newValue.storeType === 'Storefront') {
                    window.location.assign(
                      `/stores/${newValue.address.province.toLowerCase()}/${newValue.address.city.toLowerCase()}/${id}/${
                        newValue.slug
                      }`
                    );
                  } else if (newValue.storeType === 'Delivery') {
                    window.location.assign(
                      `/deliveries/${newValue.address.province.toLowerCase()}/${newValue.address.city.toLowerCase()}/${id}/${
                        newValue.slug
                      }`
                    );
                  } else if (newValue.entityType === 'BRAND') {
                    window.location.assign(`/brands/${id}/${newValue.slug}`);
                  } else if (newValue.entityType === 'PRODUCT') {
                    const product = newValue;
                    console.log('product', product);
                    const isItemBranded = product?.brand ? true : false;
                    // const entityTypeFromUrl = window.location.href.split('/')[3] && window.location.href.split('/')[3].toLowerCase();
                    const { province, city } = cookies.getLocation();
                    const storeSlug = isItemBranded ? newValue.brand.slug : newValue.stores[0].slug;
                    const productID = parseIdFromLink(newValue._links.self.href, 0, '{?projection}');
                    const startOfUrl = isItemBranded
                      ? `/brands/${storeSlug}`
                      : `/${'stores'}/${province}/${city}/${storeSlug}`.toLowerCase();
                    const productInfoUrl = `/${productID}/${product.slug}`;
                    const productPath = startOfUrl + productInfoUrl;
                    window.location.assign(productPath);
                    // newValue.brand
                    //   ? window.location.assign(`/brands/${newValue.brand.slug}/${id}/${newValue.slug}`)
                    //   : window.location.assign(`/products/${id}/${newValue.slug}`);
                    // http://localhost:3000/stores/ontario/toronto/royal-buds/351558/36-39-aaaa-layer-cake-super-sticky-tasty-topshelf-200-oz
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="stores, brands or products"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <SearchIcon className={classes.searchBoxIcon} />
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    variant="outlined"
                    className={classes.globalSearchBox}
                  />
                )}
              />
            </div>
            {/* ao: ==============================end of global search bar */}

            <div className={classes.searchToolbarInnerLocation}>
              <Typography className={classes.locationBoxLabel}>Browsing within:</Typography>
              <Autocomplete
                className={classes.locationAutocomplete}
                noOptionsText="At this time we are working to bring on partnerships with your local retailers to service this area."
                disableClearable
                disabled={locations.length === 0 || loadingNearestLoc}
                options={locations}
                getOptionLabel={(option) => `${option.city}, ${option.province}`}
                value={location}
                PopperComponent={CustomPopper}
                onChange={(event, newValue) => {
                  const province = newValue.province;
                  const city = newValue.city;

                  getLocationId(province, city)
                    .then((res) => {
                      setLocationChanged(true);
                      navBarSelectRedirect(window.location.href, province, city, res, cookies, dispatch);
                    })
                    .catch((error) => console.error(error));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputAdornment}>
                          <LocationOnIcon className={classes.searchBoxIcon} />
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    variant="outlined"
                    className={classes.locationSearchBox}
                  />
                )}
              />
              <Button
                onClick={determineClosestLocation}
                variant="contained"
                color="secondary"
                disabled={loadingNearestLoc}
                className={classes.nearestToMeButton}
              >
                {loadingNearestLoc ? <CircularProgress color="secondary" size="25px" /> : 'Nearest to me'}
              </Button>
            </div>

            <div className={classes.searchToolbarInnerLocation}>
              {isLoggedIn && (
                <div className={classes.accountMenu}>
                  <AccountMenu logout={() => logout()}></AccountMenu>
                </div>
              )}

              {!isLoggedIn && <LoginSignUpBtns />}
            </div>
          </div>
        </div>
        <div id="navInner" className={classes.appBarInnerContainer}>
          <Toolbar id="navToolBar" variant={mobile ? 'dense' : 'regular'} disableGutters>
            <IconButton
              edge="start"
              className={classes.drawerButton}
              color="inherit"
              aria-label="drawer"
              onClick={toggleDrawer('left', true)}
            >
              <MenuIcon />
            </IconButton>
            <Button
              edge="start"
              className={classes.homeButton}
              color="inherit"
              aria-label="logo"
              onClick={(event) => handleChange(event, 0)}
              component={Link}
              to={'/'}
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
                backgroundColor: 'transparent',
              }}
              disableRipple
              disableElevation
            >
              <img
                src={require(`../assets/logos/logoHeader.svg`)}
                alt={`sdaw-main-logo`}
                className={classes.logoHeader}
              />
            </Button>
            <Tabs
              id="navLinks"
              value={value}
              onChange={handleChange}
              aria-label="navigation bar tabs"
              className={classes.navBarTabs}
              classes={{
                indicator: classes.indicator,
                root: classes.tabRoot,
              }}
            >
              {NAV_OPTIONS.sort((x, y) => x.index - y.index).map((option) => (
                <Tab
                  key={option.name}
                  label={option.name}
                  className={value === option.index ? classes.selectedTab : classes.tab}
                  component={Link}
                  to={navbarTabLink(option.path, location.province, location.city)}
                  style={{ textDecoration: 'inherit' }}
                  disableRipple
                />
              ))}
              <Tab
                value={-1}
                key={'no-bar'}
                label={''}
                className={classes.tab}
                // component={Link}
                // to={option.path}
                // style={{ textDecoration: "inherit" }}
                style={{ display: 'none' }}
                disableRipple
              />
            </Tabs>
            <IconButton
              edge="start"
              className={classes.profileButton}
              color="inherit"
              aria-label="Maps"
              onClick={() => {
                window.location.assign('/maps');
              }}
            >
              <img src={MapIcon} height={21} width={21} alt={'map'} />
            </IconButton>
            <IconButton
              edge="start"
              className={classes.profileButton}
              color="inherit"
              aria-label="Profile"
              onClick={toggleDrawer('right', true)}
            >
              <AccountCircleIcon className={isLoggedIn ? null : classes.primaryColor} />
            </IconButton>
          </Toolbar>
        </div>
      </AppBar>
      <AppBarOffset />
      {/* ============================================Mobile View Drawers (Behave like modals) */}
      <SwipeableDrawer
        open={drawerState.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={drawerState.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {sideList('right')}
      </SwipeableDrawer>
    </div>
  );
}

export default NavBar;
